import React from "react"

import SEO from '../components/seo'
import Layout from '../components/layout'
import { Heading1, Paragraph } from 'styles/typography'
import { Container, SectionContainer } from 'styles/container'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <SectionContainer>
      <Heading1 style={{ textAlign: 'center' }}>Not found</Heading1>
      <Paragraph style={{ textAlign: 'center' }}>Sorry, we couldn't find what you were looking for, perhaps you haven't had your coffee yet?</Paragraph>
    </SectionContainer>
  </Layout>
)

export default NotFoundPage
